import FormPage from '@/components/FormPage'
import { createGetParams, createPostParams } from '@/rest'
import {
  formatPhoneNumber,
  getRm,
  getNumeralFromRm,
  stringGen,
} from '@/utils'

const formTitle = 'Change Black plan'

export default {
  extends: FormPage,
  data () {
    return {
      amount: 0.00,
      eSim: false,
      name: null,
      street1: null,
      street2: null,
      city: null,
      currentPlan: null,
      onGoing: false,
      postalCode: null,
      state: null,
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      title: formTitle,
      // Form steps
      planDetails: {
        fields: {
          ecash: {
            name: 'E-recharge balance',
          },
          msisdn: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'Mobile number',
            props: {
              activeOnly: true,
              existing: true,
              noPrepaid: true,
              hint: 'Black mobile number',
              label: 'Enter mobile number',
              presistentHint: true,
              required: true,
              rules: [val => !!val || 'Black mobile number required!'],
              searchNetwork: true,
            },
          },
          plan: {
            name: 'Current Black plan',
          },
          commitmentFee: {
            name: 'Current commitment fee',
          },
          amountDue: {
            name: 'Amount due',
          },
          package: {
            component: () => import(/* webpackChunkName: "blackPackagesField" */ '@/components/BlackPackagesField'),
            name: 'New Black Plan',
            props: {
              msisdn: null,
            },
          },
          studentFront: {
            name: 'Student Front',
            hide: true,
            component: () => import(/* webpackChunkName: "fileInputField" */ '@/components/FileInputField'),
            props: {
              label: 'Upload front student card ',
              rules: [],
              required: false,
            },
          },
          studentBack: {
            name: 'Student Back',
            hide: true,
            component: () => import(/* webpackChunkName: "fileInputField" */ '@/components/FileInputField'),
            props: {
              label: 'Upload back student card ',
              rules: [],
              required: false,
            },
          },
          studentId: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Student ID',
            hide: true,
            props: {
              hint: 'Exactly as in student card',
              label: 'Enter student ID',
              rules: [],
              required: false,
            },
          },
          uniState: {
            component: () => import(/* webpackChunkName: "iptastateField" */ '@/components/IptaStateField'),
            name: 'University state',
            hide: true,
            value: this.idstate,
            rules: [],
            required: false,
          },
          unipackage: {
            component: () => import(/* webpackChunkName: "iptapackagesField" */ '@/components/IptaPackagesField'),
            name: 'University',
            hide: true,
            props: {
              list: [],
              itemValue: 'idipta',
              itemText: 'iptaname',
              label: 'Select university',
              returnObject: true,
              required: false,
            },
            value: { itemText: null, itemValue: null },
          },
          yearOfStudy: {
            component: () => import(/* webpackChunkName: "yearStudyField" */ '@/components/YearStudyField'),
            name: 'Year Of Study',
            hide: true,
            props: {
              hint: 'Year of Study',
              label: 'Enter year of study',
              rules: [],
              required: false,
            },
          },
          requiredEcash: {
            name: 'Required e-recharge',
            strong: true,
          },
        },
        subTitle: 'Plan information & ecash requirement',
        title: 'Plan details',
      },
      submission: {
        fields: {
          msisdn: {
            name: 'Mobile number',
          },
          plan: {
            name: 'Current Black Plan',
          },
          package: {
            name: 'New Black plan',
            strong: true,
          },
          studentId: {
            name: 'Student ID',
            hide: true,
          },
          unipackage: {
            name: 'University',
            hide: true,
          },
          yearOfStudy: {
            name: 'Year Of Study',
            hide: true,
          },
          chargeable: {
            emphasize: true,
            name: 'Chargeable amount',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    formSteps: function () {
      return {
        planDetails: this.planDetails,
        submission: this.submission,
      }
    },
    uni: function () {
      return this.planDetails.fields.unipackage.value
    },
    ecash: function () {
      return this.planDetails.fields.ecash
    },
    package: function () {
      return this.planDetails.fields.package
    },
    requiredEcash: function () {
      const commitmentFee = this.planDetails.fields.commitmentFee.value
        ? getNumeralFromRm(this.planDetails.fields.commitmentFee.value) : 0
      const newFee = this.package.value && this.package.value.price
        ? Number(this.planDetails.fields.package.value.price) : 0
      const amountDue = this.planDetails.fields.amountDue && this.planDetails.fields.amountDue.value
        ? getNumeralFromRm(this.planDetails.fields.amountDue.value) : 0
      const requiredEcash = newFee + commitmentFee + amountDue

      return requiredEcash.toFixed(2)
    },
  },
  mounted: function () {
    const params = createGetParams({
      erecharge: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      this.ecash.value = getRm(response.data.erecharge)
    }.bind(this)).catch(error => {
      this.ecash.error = error.message
    })
    this.planDetails.validateNext = this.validateCop
  },
  watch: {
    'package.value': {
      handler (val) {
        if (!val || !val.code) return
        const requiredEcash = this.requiredEcash
        this.planDetails.fields.requiredEcash.value = getRm(requiredEcash)
        if (requiredEcash > getNumeralFromRm(this.ecash.value)) {
          this.planDetails.fields.requiredEcash.error = 'Insufficient balance!'
        } else {
          this.planDetails.fields.requiredEcash.error = null
        }

        if (this.planDetails.fields.plan.value) {
          if (this.planDetails.fields.plan.value === this.planDetails.fields.package.value.code) {
            this.planDetails.fields.plan.error = 'Current plan selected!'
          } else if ((this.planDetails.fields.plan.value === 'B59' && this.planDetails.fields.package.value.code === 'B39_PLUS20') ||
          (this.planDetails.fields.plan.value === 'B39_PLUS20' && this.planDetails.fields.package.value.code === 'B59')) {
            this.planDetails.fields.plan.error = 'Similar plan selected!'
          } else if (this.eSim && this.planDetails.fields.package.value.code === 'B10') {
            this.planDetails.fields.plan.error = 'B10 not allowed for eSim!'
          } else if (this.planDetails.fields.package.value.code === 'MYSISWA35') {
            this.planDetails.fields.uniState.hide = false
            // this.planDetails.fields.uniState.props.required = true
            // this.planDetails.fields.uniState.props.rules.push(
            //   val => !!val || 'University State is required!',
            // )
            this.planDetails.fields.studentFront.hide = false
            // this.planDetails.fields.studentFront.props.required = true
            // this.planDetails.fields.studentFront.props.rules.push(
            //   val => !!val || 'Student Front is required!',
            // )
            this.planDetails.fields.studentBack.hide = false
            // this.planDetails.fields.studentBack.props.required = true
            // this.planDetails.fields.studentBack.props.rules.push(
            //   val => !!val || 'Student Back is required!',
            // )
            this.planDetails.fields.studentId.hide = false
            // this.planDetails.fields.studentId.props.required = true
            // this.planDetails.fields.studentId.props.rules.push(
            //   val => !!val || 'Student ID is required!',
            // )
            this.planDetails.fields.unipackage.hide = false
            // this.planDetails.fields.unipackage.props.required = true
            this.planDetails.fields.yearOfStudy.hide = false
            // this.planDetails.fields.yearOfStudy.props.required = true
            // this.planDetails.fields.yearOfStudy.props.rules.push(
            //   val => !!val || 'Year Of Study required!',
            // )
            this.submission.fields.studentId.hide = false
            this.submission.fields.unipackage.hide = false
            this.submission.fields.yearOfStudy.hide = false
          } else {
            this.planDetails.fields.plan.error = null
          }
        }
        this.submission.fields.chargeable.value = getRm(Number(requiredEcash))
        this.submission.fields.package.value = this.package.value.code
      },
      deep: true,
    },
    'planDetails.fields.msisdn.value': async function (val) {
      this.onGoing = false
      this.eSim = false
      this.planDetails.fields.plan.value = null
      this.planDetails.fields.commitmentFee.value = null
      this.planDetails.fields.amountDue.value = null
      // this.planDetails.fields.package.value = {}
      this.planDetails.fields.requiredEcash.value = null
      this.submission.fields.msisdn.value = formatPhoneNumber(val)
      if (!val) return

      if ((val.length === 11 && val.startsWith('011')) ||
        (val.length === 10 && !val.startsWith('011'))) {
        if (this.planDetails.fields.package.value) {
          this.planDetails.fields.package.value = {}
          this.planDetails.fields.package.props.msisdn = this.planDetails.fields.msisdn.value
          console.log('msisdn: ', this.planDetails.fields.package.props.msisdn)
        }
        let params = createGetParams({
          target: this.planDetails.fields.msisdn.value,
        })
        if (this.planDetails.fields.package.value) {
          params = createGetParams({
            target: this.planDetails.fields.msisdn.value,
            requestedPlan: this.planDetails.fields.package.value.code,
          })
        }
        try {
          const response = await this.$rest.get('isOnGoingCop.php', params)

          if (response.data.newPlan) {
            this.planDetails.fields.plan.error = 'COP in progress: ' +
                response.data.newPlan + ' (' + response.data.entry + ')'
            return
          }
        } catch (e) {
          this.planDetails.fields.plan.error = e.message
          return
        }
        try {
          await this.$rest.get('validateCop.php', params)
        } catch (e) {
          this.planDetails.fields.plan.error = true
          if (e.response) {
            this.planDetails.fields.plan.value = this.currentPlan = e.response.data
          } else {
            this.planDetails.fields.plan.value = this.currentPlan = e.message
          }
          return
        }

        try {
          const response = await this.$rest.get('getCopFees.php', params)
          this.planDetails.fields.amountDue.value = getRm(response.data.amountDue)
          this.planDetails.fields.commitmentFee.value = getRm(response.data.currentCommitment)
          this.planDetails.fields.requiredEcash.value = getRm(this.requiredEcash)
        } catch (e) {
          console.log(e)
          // this.planDetails.fields.plan.error = true
          if (e.response) {
            this.planDetails.fields.plan.error = this.currentPlan = e.response.data
          } else {
            this.planDetails.fields.plan.error = this.currentPlan = e.message
          }
          return
        }

        setTimeout(() => {
          if (this.planDetails.fields.msisdn.error) return

          params = createGetParams({
            target: this.planDetails.fields.msisdn.value,
          })
          this.$rest.get('getAmountDue.php', params)
            .then(response => {
              this.eSim = response.data.eSim
              this.name = response.data.name
              this.street1 = response.data.street1 ? response.data.street1 : null
              this.street2 = response.data.street2 ? response.data.street2 : null
              this.city = response.data.city ? response.data.city : null
              this.postalCode = response.data.postalCode ? response.data.postalCode : null
              this.state = response.data.state ? response.data.state : null
              this.planDetails.fields.plan.value = this.currentPlan = response.data.plan
              if (this.planDetails.fields.package.value) {
                if (this.planDetails.fields.plan.value === this.planDetails.fields.package.value.code) {
                  this.planDetails.fields.plan.error = 'Current plan selected!'
                } else if ((this.planDetails.fields.plan.value === 'B59' && this.planDetails.fields.package.value.code === 'B39_PLUS20') ||
                (this.planDetails.fields.plan.value === 'B39_PLUS20' && this.planDetails.fields.package.value.code === 'B59')) {
                  this.planDetails.fields.plan.error = 'Similar plan selected!'
                } else if (this.eSim && this.planDetails.fields.package.value.code === 'B10') {
                  this.planDetails.fields.plan.error = 'B10 not allowed for eSim!'
                } else {
                  this.planDetails.fields.plan.error = null
                }
              }
            }).catch(() => {
            // ignore
            })
        }, 1000)
      } else {
        this.planDetails.fields.plan.error = null
      }
    },
    'planDetails.fields.plan.value': function (val) {
      if (val && this.planDetails.fields.package.value) {
        if (this.planDetails.fields.package.value.code === val) {
          this.planDetails.fields.package.value = {}
        }
      }
      this.submission.fields.plan.value = val
    },
    'planDetails.fields.studentId.value': function (val) {
      this.submission.fields.studentId.value = val
    },

    'planDetails.fields.uniState.value': function (val) {
      const params = createGetParams({
        idstate: this.planDetails.fields.uniState.value,
      })
      this.$rest.get('getUniversityName.php', params)
        .then(response => {
          this.planDetails.fields.unipackage.props.list = response.data
        })
    },
    uni: function (val) {
      if (!val) return
      this.submission.fields.unipackage.value = val.iptaname
    },
    'planDetails.fields.yearOfStudy.value': function (val) {
      this.submission.fields.yearOfStudy.value = val
    },
  },
  methods: {
    // Override
    async validateCop () {
      const parameters = {
        target: this.planDetails.fields.msisdn.value,
        requestedPlan: this.planDetails.fields.package.value.code,
      }
      const params = createGetParams(parameters)

      await this.$rest.get('validateCop.php', params)
    },
    async submit () {
      this.amount = Number(getNumeralFromRm(this.planDetails.fields.requiredEcash.value)).toFixed(2)
      const params = createPostParams({
        action: 'cop',
        payload: {
          target: this.planDetails.fields.msisdn.value,
          amount: this.amount,
          oldPlan: this.planDetails.fields.plan.value,
          newPlan: this.planDetails.fields.package.value.code,
          idiptas: this.planDetails.fields.unipackage.value.idipta,
          studentId: this.planDetails.fields.studentId.value,
          yearOfStudy: this.planDetails.fields.yearOfStudy.value,
          studentIdFrontImage: this.planDetails.fields.studentFront.value,
          studentIdBackImage: this.planDetails.fields.studentBack.value,
        },
      })

      try {
        const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to change Black plan was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          this.isImpersonated() && this.showSubmissionResponse(response.data)
          this.downloadReceipt()
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
        })
      }
    },
    async downloadReceipt () {
      let invoice = 'B-' + stringGen(9)
      invoice = invoice.toUpperCase()

      const payload = {
        msisdn: this.planDetails.fields.msisdn.value,
        plan: this.planDetails.fields.package.value.code,
        amount: this.amount,
        invoice: invoice,
        name: this.name,
        street1: this.street1,
        street2: this.street2,
        city: this.city,
        postalCode: this.postalCode,
        state: this.state,
      }

      const data = process.env.NODE_ENV === 'production' ? { payload: payload }
        : { impersonate: 223992, dbname: 'onesys', payload: payload }
      /* eslint-disable no-empty */
      try {
        const response = await this.$rest({
          url: process.env.NODE_ENV === 'production' ? location.protocol + '//' + location.hostname + '/api/pdf/copReceipt.php'
            : 'http://onesys.onexox.my/pdf/copReceipt.php',
          method: 'POST',
          responseType: 'blob',
          data: data,
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', 'black_cop_receipt.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (e) {}
    },
  },
}
